import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./Login";
import Signup from "./Signup";
import ConfirmAccount from "./ConfirmAccount";
import LandingPage from "./LandingPage";

function App() {
  const [session, setSession] = useState(null);
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [email, setEmail] = useState("");

  // Check for existing session in localStorage on app load
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setSession({ token: storedToken }); // Restore session if token exists
    }
  }, []);

  const handleLogin = (session) => {
    const token = session.getIdToken().getJwtToken();
    setSession({ token }); // Store session in state
    localStorage.setItem("token", token); // Persist session token in localStorage
    setEmail(""); // Clear email from state
  };

  const handleLogout = () => {
    setSession(null);
    localStorage.removeItem("token"); // Clear session token from localStorage
  };

  const handleSignUp = (email) => {
    setIsSignedUp(true);
    setEmail(email);
  };

  return (
    <Router>
      <Routes>
        {/* Default route redirects to Login or LandingPage based on session */}
        <Route
          path="/"
          element={
            session ? (
              <Navigate to="/landing" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* Route for Login */}
        <Route
          path="/login"
          element={
            session ? (
              <Navigate to="/landing" />
            ) : (
              <Login onLogin={handleLogin} />
            )
          }
        />

        {/* Route for Signup */}
        <Route
          path="/signup"
          element={
            session ? (
              <Navigate to="/landing" />
            ) : (
              <Signup onSignUp={handleSignUp} />
            )
          }
        />

        {/* Route for ConfirmAccount */}
        <Route
          path="/confirm-account"
          element={
            isSignedUp ? (
              <ConfirmAccount email={email} />
            ) : (
              <Navigate to="/signup" />
            )
          }
        />

        {/* Route for LandingPage */}
        <Route
          path="/landing"
          element={
            session ? (
              <LandingPage token={session.token} onLogout={handleLogout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* Redirect any unknown route to Login */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
